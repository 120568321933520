import React from "react";
import momentjs from "moment";
import PropTypes from "prop-types";
import styled from "styled-components";
import { injectIntl } from "react-intl";

import OrderDetail from "Components/OrderDetail";
import { formatAsCurrency } from "Libs/utils";
import SettingLine from "Components/SettingLine";
import Loading from "Components/Loading";
import Heading3 from "Components/styleguide/Heading3";

const Layout = styled.div`
  margin-bottom: 32px;
  h3 {
    font-size: 16px;
    font-weight: 700;
    line-height: 22px;
    margin-bottom: 24px;
  }
`;

const InfoLayout = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  justify-content: space-between;
  font-size: 15px;
  color: #4b6180;
  strong {
    font-weight: 600;
  }
  span {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
`;

const OrderItemList = props => {
  const {
    orders,
    orderDetails,
    expandedItem,
    title,
    loadOrder,
    expand,
    intl
  } = props;

  const orderDate = date => momentjs(date).format("D MMM, YYYY");
  const billingCycle = date => momentjs(date).format("MMMM YYYY");
  const orderFilter = order => {
    const title = order.id;

    if (!title || !props.titleFilter) {
      return true;
    }

    return title.includes(props.titleFilter);
  };

  const orderTotal = (components, currency) => {
    const total = Object.keys(components).reduce((total, component) => {
      return (total = total + components[component].amount);
    }, 0);

    return formatAsCurrency(total, currency);
  };

  return (
    <Layout>
      <Heading3>{title}</Heading3>

      {props.isLoading ? (
        <Loading />
      ) : (
        orders &&
        orders
          .sort((a, b) => {
            if (a.billing_period_end < b.billing_period_end) return 1;
            if (a.billing_period_end > b.billing_period_end) return -1;
            return 0;
          })
          .filter(orderFilter)
          .map(order => (
            <SettingLine
              key={order.id}
              id={`billing-history-recuring-${order.id}`}
              info={
                <InfoLayout>
                  <span className="col-2">
                    <strong>{order.id}</strong>
                  </span>

                  <span className="col-3">
                    {orderDate(order.billing_period_start)} -{" "}
                    {orderDate(order.billing_period_end)}
                  </span>
                  <span className="col-2">
                    {orderTotal(
                      order.components,
                      order.components.base_price.currency
                    )}
                  </span>
                  <span className="col-2">{`${
                    order.status === "recurring_open"
                      ? " Upcoming"
                      : " Completed"
                  }`}</span>
                </InfoLayout>
              }
              isOpen={expandedItem === order.id}
              openText={intl.formatMessage({ id: "view" })}
              onClick={() => {
                expand(order.id);
                loadOrder(order.id);
              }}
            >
              {expandedItem === order.id &&
                orderDetails[order.id] && (
                  <OrderDetail
                    loading={orderDetails[order.id].loading}
                    order={orderDetails[order.id].data}
                    billingCycle={billingCycle(order.billing_period_end)}
                  />
                )}
            </SettingLine>
          ))
      )}
    </Layout>
  );
};

OrderItemList.propTypes = {
  orders: PropTypes.object,
  orderDetails: PropTypes.object,
  expandedItem: PropTypes.string,
  title: PropTypes.string,
  loadOrder: PropTypes.func,
  expand: PropTypes.func,
  intl: PropTypes.object,
  titleFilter: PropTypes.string,
  isLoading: PropTypes.bool
};

export default injectIntl(OrderItemList);
