import React from "react";
import styled, { keyframes } from "styled-components";
import PropTypes from "prop-types";

const spinAnimationOpen = keyframes`
  0% {
    transform: rotate(0deg)
  }
  100% {
    transform: rotate(180deg)
  }
`;

const spinAnimationClose = keyframes`
  0% {
    transform: rotate(180deg)
  }
  100% {
    transform: rotate(0deg)
  }
`;

const Chevron = styled.span`
  width: 26px;
  height: auto;
  align-items: center;
  display: ${props => (props.hideArrow ? "none" : "flex")};
  animation: ${props =>
      props.animate && (props.isOpen ? spinAnimationOpen : spinAnimationClose)}
    0s linear;
  animation-fill-mode: forwards;
  svg {
    max-width: 100%;
  }
`;

const ChevronIcon = props => (
  <Chevron
    className={`${props.className ? "chevron " + props.className : "chevron"}`}
    {...props}
  >
    <svg
      width={`${props.width ? props.width : "24"}`}
      height={`${props.height ? props.height : "24"}`}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M6.90002 9.8999C6.90002 9.5999 7.00002 9.3999 7.20002 9.1999C7.60002 8.7999 8.20002 8.7999 8.60002 9.1999L12.1 12.6999L15.6 9.1999C16 8.7999 16.6 8.7999 17 9.1999C17.4 9.5999 17.4 10.1999 17 10.5999L12.8 14.7999C12.4 15.1999 11.8 15.1999 11.4 14.7999L7.20002 10.5999C7.00002 10.3999 6.90002 10.1999 6.90002 9.8999Z"
        fill={`${props.color ? props.color : "#5f5e70"}`}
      />
    </svg>
  </Chevron>
);

ChevronIcon.propTypes = {
  className: PropTypes.string,
  animate: PropTypes.bool,
  color: PropTypes.string,
  width: PropTypes.number,
  height: PropTypes.number
};

export default ChevronIcon;
