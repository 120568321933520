import styled from "styled-components";

export default styled.button`
  background-color: ${props => props.theme.buttonBg};
  color: ${props => props.theme.buttonText};
  border: none;
  border-radius: 2px;
  padding: 0px 24px 2px;
  height: 32px;
  line-height: 30px;
  cursor: pointer;
  font-size: 14px;
  font-weight: 500;
  &:first-letter {
    text-transform: uppercase;
  }
  &:hover {
    background-color: ${props => props.theme.buttonHover};
  }
  &:focus {
    border: 1px solid ${props => props.theme.buttonHover};
    margin: -1px;
    line-height: 30px;
    box-shadow: 0 0 2px ${props => props.theme.buttonHover};
    outline: none;
  }
  &:active {
    margin: 0;
    box-shadow: none;
    border: none;
    background-color: ${props => props.theme.buttonHover};
  }
  &.secondary {
    background: transparent;
    color: #4a495e;
    &:hover {
      background-color: #ededed;
    }
    &:focus {
      margin-left: inherit;
    }
    &:active {
      background-color: #ededed;
    }
  }
  &.outline {
    background: transparent;
    color: ${props => props.theme.links};
    border: 1px solid #c9d0e4;
    margin: -1px;
    line-height: 30px;
    &:hover {
      background-color: ${props => props.theme.buttonHover};
      color: ${props => props.theme.buttonText};
      border: none;
      margin: 0;
    }
    &:focus {
      border: 1px solid ${props => props.theme.buttonHover};
      margin: -1px;
      line-height: 30px;
      box-shadow: 0 0 2px ${props => props.theme.buttonHover};
      outline: none;
    }
    &:active {
      margin: 0;
      box-shadow: none;
      border: none;
      background-color: ${props => props.theme.buttonHover};
    }
  }
  &:disabled {
    opacity: 0.8;
    cursor: not-allowed;
  }
  &.edit {
    width: 80px;
    font-size: 15px;
    font-weight: 600;
    padding: 0 0;
    text-align: right;
    cursor: pointer;
    background: transparent;
    border-radius: none;
    border: none;
    color: #4a495e;
    img {
      margin-left: 10px;
    }
  }
  &.delete {
    color: ${props => props.theme.error};
    font-size: 14px;
    cursor: pointer;
    background: transparent;
    border-radius: none;
    border: none;
    float: right;
    padding: 0;
  }
  &:first-letter {
    text-transform: uppercase;
  }
  &.link-style {
    color: ${props => props.theme.links};
    background: transparent;
    border: none;
    height: auto;
    line-height: inherit;
    position: relative;
    padding: 0 8px;
    font-weight: 400;
    &:hover {
      &:after {
        content: "";
        width: calc(100% - 16px);
        position: absolute;
        height: 1px;
        background: ${props => props.theme.links};
        bottom: -1px;
        left: 8px;
        display: inline-block;
      }
    }
    &:focus {
      outline: none;
      border: 1px solid ${props => props.theme.buttonHover};
      border-radius: 4px;
      box-shadow: 0 0 2px ${props => props.theme.buttonHover};
      text-decoration: none;
      margin: -1px -9px;
    }
    &:active {
      border: none;
      box-shadow: none;
      margin: 0 -8px;
    }
  }
`;
