import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import { connect } from "react-redux";
import { Map } from "immutable";
import ImmutablePropTypes from "react-immutable-proptypes";
import momentjs from "moment";

import OrderItemList from "Components/OrderItemList";

const Layout = styled.div`
  .header-arrow + .header-arrow {
    border-top: 1px solid #d8e2ef;
  }
`;

class BillingListField extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
    this.loadOrder = this.loadOrder.bind(this);
    this.expand = this.expand.bind(this);
  }

  componentWillMount() {
    this.props.loadOrders();
  }

  loadOrder(orderId) {
    this.props.loadOrder(orderId);
  }

  orderDate(date) {
    return momentjs(date).format("D MMMM YYYY");
  }

  expand(id) {
    if (this.state.expandedItem === id) {
      this.setState({ expandedItem: null });
    } else {
      this.setState({ expandedItem: id });
    }
  }

  render() {
    const { orders, ordersAreLoading, ordersById } = this.props;
    const previous = orders.get("previous", new Map()).valueSeq();
    const recurring = orders.get("recurring", new Map()).valueSeq();
    const orderDetails = ordersById.toJS();

    return (
      <Layout>
        <React.Fragment>
          <OrderItemList
            orders={recurring}
            // @todo: translate this.
            title="Recurring Platform.sh subscription"
            loadOrder={this.loadOrder}
            isLoading={ordersAreLoading}
            expand={this.expand}
            orderDetails={orderDetails}
            expandedItem={this.state.expandedItem}
            titleFilter={this.props.titleFilter}
          />
          <OrderItemList
            orders={previous}
            // @todo: translate this.
            title="Previous Orders"
            loadOrder={this.loadOrder}
            isLoading={ordersAreLoading}
            expand={this.expand}
            orderDetails={orderDetails}
            expandedItem={this.state.expandedItem}
            titleFilter={this.props.titleFilter}
          />
        </React.Fragment>
      </Layout>
    );
  }
}

BillingListField.propTypes = {
  organizationId: PropTypes.string,
  orders: PropTypes.object,
  titleFilter: PropTypes.string,
  loadOrder: PropTypes.func,
  loadOrders: PropTypes.func,
  ordersAreLoading: PropTypes.bool,
  ordersById: ImmutablePropTypes.map
};

const mapStateToProps = (state, props) => ({
  orders: state.order.getIn(["data", props.organizationId], new Map()),
  ordersAreLoading: state.order.get("loading", false),
  ordersById: state.order.get("byId", new Map())
});

const mapDispatchToProps = (dispatch, props) => ({
  loadOrders: () =>
    import("Reducers/organization/order").then(reducer =>
      dispatch(reducer.loadOrders(props.organizationId))
    ),
  loadOrder: orderId =>
    import("Reducers/organization/order").then(reducer =>
      dispatch(reducer.loadOrder(orderId))
    )
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(BillingListField);
