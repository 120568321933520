import React from "react";
import PropTypes from "prop-types";

import TableRow from "./TableRow";
import TableCell from "./TableCell";
import { formatAsCurrency } from "Libs/utils";

const OrderDetailItemTotal = ({ lineItems, expanded }) => {
  // Assume the currency is the same for all components of the line item.
  const currency = lineItems[0].components.base_price.currency;
  const defaultTotal = {
    totalPrice: {
      amount: 0,
      displayTitle: "Total"
    }
  };

  // Nested loops here because we need to add up the totals for *each project*
  // based on each component *within the project*.
  const totals = lineItems.reduce((totals, item) => {
    Object.keys(item.components).forEach(component => {
      if (totals[component]) {
        totals[component].amount =
          item.components[component].amount + totals[component].amount;
      } else {
        totals[component] = {
          amount: item.components[component].amount,
          displayTitle: item.components[component].display_title
        };
      }
    });

    totals.totalPrice.amount = totals.totalPrice.amount + item.total_price;
    return totals;
  }, defaultTotal);

  return (
    <React.Fragment>
      {expanded && (
        <React.Fragment>
          {/* @todo: translate these labels */}
          {Object.keys(totals)
            .filter(item => item !== "totalPrice")
            .map((item, i) => (
              <TableRow className="footer-row" key={i}>
                <TableCell>{totals[item].displayTitle}</TableCell>
                <TableCell className="cell total">
                  {formatAsCurrency(totals[item].amount, currency)}
                </TableCell>
              </TableRow>
            ))}
        </React.Fragment>
      )}
      <TableRow className="total-row footer-row">
        <TableCell>{totals.totalPrice.displayTitle}</TableCell>
        <TableCell className="total">
          {formatAsCurrency(totals.totalPrice.amount, currency)}
        </TableCell>
      </TableRow>
    </React.Fragment>
  );
};

OrderDetailItemTotal.propTypes = {
  lineItems: PropTypes.array,
  expanded: PropTypes.bool
};

export default OrderDetailItemTotal;
