import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import ChevronIcon from "Icons/ChevronIcon";
import CloseIcon from "Icons/CloseIcon";
import Button from "Components/Button";

const SettingsLineWrapper = styled.div`
  margin-bottom: 8px;
  border-radius: 4px;
  background: #fff;
  &.open {
    margin: 16px 0;
    box-shadow: 0 10px 20px rgba(75, 97, 128, 0.08);
    .setting-line {
      background: #f5f7fa;
      border-radius: 4px 4px 0 0;
      &:hover {
        background: #f5f7fa;
        box-shadow: none;
        .open-text {
          display: none;
        }
      }
    }
    .action svg {
      transform: rotate(180deg);
    }
  }
  form {
    padding: 24px 32px;
    .toggle-field {
      margin-bottom: 24px;
      &:last-child {
        margin-bottom: 0;
      }
    }
    .toggle-field + .toggle-field,
    .toggle-field + .actions {
      border-top: 1px solid #c9d0e4;
      padding-top: 24px;
    }
    .select-box__control {
      min-height: 38px;
      height: 40px;
      .select-box__value-container {
        height: 40px;
        .select-box__single-value {
          height: 40px;
          line-height: 38px;
        }
      }
    }
  }
`;
const SettingLineLayout = styled.div`
  display: flex;
  flex-direction: row;
  flex-shrink: 0;
  align-items: center;
  justify-content: space-between;
  padding: 0 32px;
  min-height: 60px;
  box-sizing: border-box;
  font-size: 16px;
  color: #38485e;
  border-radius: 4px;
  background: #fff;
  text-decoration: none;
  div:first-child {
    width: calc(100% - 60px);
  }
  div:nth-of-type(2) {
    width: auto;
    text-align: right;
    width: 206px;
    display: flex;
    justify-content: flex-end;
  }
  .open-text {
    display: none;
    color: ${props => props.theme.links};
  }
  button {
    cursor: pointer;
    background: transparent;
    padding: 0;
    display: flex;
    align-items: center;
    &:hover,
    &:focus {
      background: transparent;
      padding: 0;
      margin: 0;
      outline: none;
      box-shadow: none;
      border: none;
      .open-text {
        display: inline-block;
        &:first-letter {
          text-transform: uppercase;
        }
      }
      .chevron svg path {
        fill: ${props => props.theme.links};
      }
    }
  }
  &:hover {
    background: ${props => props.theme.highlightBg};
    box-shadow: 0 10px 20px rgba(75, 97, 128, 0.08);
    .open-text {
      display: inline-block;
      &:first-letter {
        text-transform: uppercase;
      }
    }
    .chevron svg path {
      fill: ${props => props.theme.links};
    }
  }
`;

const SettingLine = props => {
  return (
    <SettingsLineWrapper
      className={`settings-item${props.isOpen ? " open" : ""}`}
    >
      <SettingLineLayout className="setting-line">
        {props.isNew ? (
          <div id={props.id}>
            <strong>{props.addNewTitle ? props.addNewTitle : "Add"}</strong>
          </div>
        ) : (
          <div id={props.id} className="setting-line-info">
            {props.info}
          </div>
        )}
        {!props.noToggle && (
          <div className="action">
            {props.isNew ? (
              <Button
                id={`${props.id}-edit`}
                onClick={e => {
                  e.preventDefault();
                  props.onClick();
                }}
              >
                <CloseIcon />
              </Button>
            ) : (
              <Button
                id={`${props.id}-edit`}
                onClick={e => {
                  e.preventDefault();
                  props.onClick();
                  document.activeElement.blur();
                }}
              >
                <span className="open-text">{props.openText}</span>{" "}
                <ChevronIcon />
              </Button>
            )}
          </div>
        )}
        {props.customLink && (
          <div className="custom-link">{props.customLink}</div>
        )}
      </SettingLineLayout>
      {props.children}
    </SettingsLineWrapper>
  );
};

SettingLine.propTypes = {
  id: PropTypes.string,
  icon: PropTypes.node,
  info: PropTypes.node,
  children: PropTypes.node,
  openText: PropTypes.string,
  onClick: PropTypes.func,
  isOpen: PropTypes.bool,
  isNew: PropTypes.bool,
  addNewTitle: PropTypes.string,
  noToggle: PropTypes.bool,
  customLink: PropTypes.node
};

export default SettingLine;
