import React from "react";
import styled from "styled-components";
import { injectIntl } from "react-intl";
import PropTypes from "prop-types";

import LoadingIcon from "Icons/LoadingIcon";

const LoadingWrapper = styled.span`
  display: flex;
  align-items: center;
  span {
    display: inline-block;
    color: rgb(22, 100, 255);
    font-weight: 600;
    &:first-letter {
      text-transform: uppercase;
    }
  }
  &.icon-only {
    display: flex;
    justify-content: center;
    align-itmes: center;
    padding-top: 50px;
  }
`;

const Loading = ({ intl, iconOnly, loadType }) => (
  <LoadingWrapper className={`loading${iconOnly ? " icon-only" : ""}`}>
    <LoadingIcon />
    {!iconOnly && (
      <span>
        {intl.formatMessage({ id: "loading" })}
        {loadType ? " " + loadType : ""}...
      </span>
    )}
  </LoadingWrapper>
);

Loading.propTypes = {
  intl: PropTypes.object,
  iconOnly: PropTypes.bool,
  loadType: PropTypes.string
};

export default injectIntl(Loading);
