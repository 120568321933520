import React from "react";
import PropTypes from "prop-types";
import { FormattedMessage } from "react-intl";

import ContentLayout from "Components/ContentLayout";
import Heading2 from "Components/styleguide/Heading2";
import BillingListField from "Containers/BillingListField";

class OrganizationBilling extends React.Component {
  render() {
    const { params } = this.props;
    return (
      <ContentLayout id="settings-billing" className="settings-content">
        <Heading2>
          <FormattedMessage id="billing_history" />
        </Heading2>
        <BillingListField
          organizationId={params.organizationId}
          titleFilter={this.props.titleFilter}
        />
      </ContentLayout>
    );
  }
}

OrganizationBilling.propTypes = {
  router: PropTypes.shape({
    push: PropTypes.func.isRequired
  }),
  params: PropTypes.shape({
    organizationId: PropTypes.string
  }),
  location: PropTypes.shape({
    pathname: PropTypes.string.isRequired
  }),
  titleFilter: PropTypes.string
};
export default OrganizationBilling;
