import React from "react";
import PropTypes from "prop-types";
import momentjs from "moment";
import styled from "styled-components";

import Loading from "Components/Loading";
import Table from "./Table";
import TableRow from "./TableRow";
import TableCell from "./TableCell";
import Heading3 from "Components/styleguide/Heading3";
import Heading4 from "Components/styleguide/Heading4";
import ChevronIcon from "Icons/ChevronIcon";
import Button from "Components/Button";
import ButtonLink from "Components/ButtonLink";
import { formatAsCurrency } from "Libs/utils";
import OrderDetailProjectTotal from "Components/OrderDetailProjectTotal";

const Layout = styled.div`
  padding: 32px;
  .table-layout {
    margin: 0 -32px;
    width: calc(100% + 64px);
    font-size: 15px;
    .product {
      min-width: 372px;
    }
    .total {
      min-width: 214px;
      width: 214px;
      font-weight: 600;
    }
    .footer-row {
      margin: 0;
      padding: 0 16px;
      width: 100%;
    }
    strike {
      margin-left: 16px;
    }
    footer {
      margin-bottom: 32px;
    }
  }
  .total-row {
    border-top: 1px solid ${props => props.theme.brandColor};
    margin-bottom: 32px;
    font-weight: 700;
    .total {
      font-weight: 700;
    }
  }
  .order-totals {
    margin-bottom: 32px;
    .footer-row {
      border-bottom: none;
      &:first-child {
        border-bottom: 1px solid #c9d0e4;
      }
      &.order-total {
        font-weight: 700;
        background: ${props => props.theme.highlightBg};
        border-top: 1px solid ${props => props.theme.links};
        border-bottom: 1px solid ${props => props.theme.links};
        .total {
          font-weight: 700;
        }
      }
    }
  }
`;

const HeaderToggle = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  margin-bottom: 24px;
  h3 {
    margin: 0 !important;
  }
  .open-text {
    display: none;
    color: ${props => props.theme.links};
  }
  button {
    cursor: pointer;
    background: transparent;
    padding: 0;
    display: flex;
    align-items: center;
    height: 22px;
    line-height: 22px;
    &:hover,
    &:focus {
      background: transparent;
      padding: 0;
      margin: 0;
      outline: none;
      box-shadow: none;
      border: none;
      .open-text {
        display: inline-block;
        line-height: 22px;
        &:first-letter {
          text-transform: uppercase;
        }
      }
      .chevron svg path {
        fill: ${props => props.theme.links};
      }
    }
  }
  &:hover {
    .open-text {
      display: inline-block;
      &:first-letter {
        text-transform: uppercase;
      }
    }
    .chevron svg path {
      fill: ${props => props.theme.links};
    }
  }
  &.open {
    .open-text {
      display: none;
    }
    .chevron svg {
      transform: rotate(180deg);
    }
  }
  &.open:hover {
    .open-text {
      display: none;
    }
  }
`;

const OrderInfoWrapper = styled.div`
  display: flex;
  justify-content: stretch;
  align-items: flex-start;
  width: 100%;
  margin-bottom: 32px;
  line-height: 20px;
  > div {
    width: 50%;
    &:first-child {
      margin-right: 32px;
    }
  }
  h4 {
    font-weight: normal;
    font-size: 16px;
    margin: 0 0 16px;
    text-transform: none;
  }
`;

const InfoColumn = styled.div`
  width: 50%;
  &:first-child {
    margin-right: 32px;
  }
  > div {
    margin-bottom: 5px;
  }
  h4 {
    font-weight: normal;
    font-size: 16px;
    margin: 0 0 16px;
    text-transform: none;
  }
`;

class OrderDetail extends React.Component {
  constructor() {
    super();
    this.state = {};
    this.updateActiveOrder = this.updateActiveOrder.bind(this);
  }

  formatDate(date) {
    return momentjs(date).format("MMM D");
  }

  formatTotal(order) {
    return (
      (order.components.base_price.amount + order.components.discount.amount) *
      order.quantity
    );
  }

  updateActiveOrder(id) {
    if (this.state.expandedSubscription === id) {
      this.setState({ expandedSubscription: null });
    } else {
      this.setState({ expandedSubscription: id });
    }
  }

  lineItemsByOrder(order) {
    let lineItemsByOrder;
    if (order && order.line_items) {
      lineItemsByOrder = order.line_items.reduce((items, item) => {
        if (!items[item.subscription_id]) {
          items[item.subscription_id] = { title: item.project, line_items: [] };
        }
        items[item.subscription_id].line_items.push(item);
        return items;
      }, {});
    }

    return lineItemsByOrder;
  }

  orderTotal(components, currency) {
    const total = Object.keys(components).reduce((total, component) => {
      return (total = total + components[component].amount);
    }, 0);

    return formatAsCurrency(total, currency);
  }

  render() {
    const { loading, order, billingCycle } = this.props;
    let lineItemsByOrder;
    let orderCurrency;
    if (order) {
      lineItemsByOrder = this.lineItemsByOrder(order);
      orderCurrency = order.components.base_price.currency;
    }

    return (
      <Layout>
        {loading ? (
          <Loading />
        ) : (
          <React.Fragment>
            {order &&
              Object.keys(lineItemsByOrder).map(subscriptionId => (
                <div key={subscriptionId}>
                  <HeaderToggle
                    className={`project-details${
                      this.state.expandedSubscription === subscriptionId
                        ? " open"
                        : ""
                    }`}
                  >
                    <Heading3
                      className={
                        lineItemsByOrder[subscriptionId].title ? "" : "untitled"
                      }
                    >
                      {lineItemsByOrder[subscriptionId].title
                        ? lineItemsByOrder[subscriptionId].title
                        : "Untitled Project"}
                    </Heading3>
                    <Button
                      onClick={() => {
                        this.updateActiveOrder(subscriptionId);
                        document.activeElement.blur();
                      }}
                    >
                      <span className="open-text">View</span> <ChevronIcon />
                    </Button>
                  </HeaderToggle>
                  {this.state.expandedSubscription === subscriptionId && (
                    <React.Fragment>
                      <Table
                        headers={[
                          "Product",
                          "Duration",
                          "Unit Price",
                          "Quantity",
                          "Total"
                        ]}
                      >
                        {lineItemsByOrder[subscriptionId].line_items.map(
                          (item, index) => (
                            <TableRow key={index}>
                              <TableCell className="cell product">
                                {item.product}
                              </TableCell>
                              <TableCell className="cell duration">
                                {`${this.formatDate(
                                  item.start
                                )} - ${this.formatDate(item.end)}`}
                              </TableCell>
                              <TableCell className="cell unit-price">
                                {formatAsCurrency(
                                  item.components.base_price.amount,
                                  orderCurrency
                                )}
                                <strike>
                                  {formatAsCurrency(
                                    item.components.discount.amount,
                                    orderCurrency
                                  )}
                                </strike>
                              </TableCell>
                              <TableCell className="cell quantity">
                                {item.quantity}
                              </TableCell>
                              <TableCell className="cell total">
                                {this.formatTotal(item)}
                              </TableCell>
                            </TableRow>
                          )
                        )}
                      </Table>
                    </React.Fragment>
                  )}
                  <Table
                    footer={
                      <OrderDetailProjectTotal
                        expanded={
                          this.state.expandedSubscription === subscriptionId
                        }
                        lineItems={lineItemsByOrder[subscriptionId].line_items}
                      />
                    }
                  />
                </div>
              ))}
            <Table
              className="order-totals"
              footer={
                <React.Fragment>
                  {Object.keys(order.components).map((component, i) => (
                    <TableRow className="footer-row" key={i}>
                      <TableCell>
                        {order.components[component].display_title}
                      </TableCell>
                      <TableCell className="total">
                        {formatAsCurrency(
                          order.components[component].amount,
                          order.components[component].currency
                        )}
                      </TableCell>
                    </TableRow>
                  ))}
                  <TableRow className="footer-row order-total">
                    <TableCell>Order Total</TableCell>
                    <TableCell className="total">
                      {this.orderTotal(order.components, orderCurrency)}
                    </TableCell>
                  </TableRow>
                </React.Fragment>
              }
            />
            <footer>
              <OrderInfoWrapper>
                <InfoColumn>
                  <Heading4>Billing Information</Heading4>
                  <div>{order.address.name_line}</div>
                  <div>{order.address.country}</div>
                </InfoColumn>
                <InfoColumn>
                  {order.vat_number && (
                    <Heading4>EU VAT INFO: {order.vat_number}</Heading4>
                  )}
                  <div>
                    <strong>Billing cycle</strong>
                  </div>
                  <div>{billingCycle}</div>
                </InfoColumn>
              </OrderInfoWrapper>
              {order.invoice_url && (
                <ButtonLink
                  to={`${order.invoice_url}?download=1`}
                  text="Download"
                  external={true}
                />
              )}
            </footer>
          </React.Fragment>
        )}
      </Layout>
    );
  }
}

OrderDetail.propTypes = {
  order: PropTypes.object,
  loading: PropTypes.bool,
  billingCycle: PropTypes.string
};

export default OrderDetail;
