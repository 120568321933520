import styled from "styled-components";

export default styled.h4`
  font-family: "Open Sans", Helvetica, Arial, sans-serif;
  font-weight: 600;
  font-size: 14px;
  text-transform: uppercase;
  letter-spacing: 0.6px;
  color: #302f45;
`;
